import {createContext, h} from 'preact'
import {useEffect, useState} from "preact/hooks";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {
    createBooking,
    getSalonInfo,
    sendVerification,
    verifyPhoneNumber
} from "../data-providers/booking-salon-data-provider";
import {timeout} from "../utils/functions";
import {useDidUpdate} from "../utils/hooks/useDidUpdate";

export const BookingContext = createContext({open: false, setOpen: null});

const defaultContex = {
    bookingPopupStep: 0,
    salonInfo: null,
    phoneNumber: null,
    userName: null,
    isLoaded: false,
    verifiedCode: true,
    oneTimeCode: null,
}

export default (props) => {

    let salonId = props.salonId
    const [context, setContext] = useState(defaultContex)
    const [timeRemain, setTimeRemain] = useState(0);
    const [resetInterval, setResetInterval] = useState(false)
    
    const {executeRecaptcha} = useGoogleReCaptcha();
    const handleReCaptchaVerify = async () => {
        let env = process.env.PREACT_APP_ENV || 'prod'
        if (window.navigator.userAgent.indexOf('HeadlessChrome') && env === 'stg') {
            return ''
        }
        return  await executeRecaptcha('sendOtp');

    };
    useDidUpdate(() => {
        if (!resetInterval || !timeRemain) {
            return
        }
        setTimeout(function () {
            if (timeRemain === 1) {
                setResetInterval(false)
            }
            setTimeRemain(timeRemain - 1)
        }, 1000)
    }, [context.bookingPopupStep, timeRemain])

    const setBookingStep = (step, additional = {}) => {
        setContext({...context, bookingPopupStep: step || 0, ...additional})
    }


    const enterUser = (phone, userName) => {
        if (timeRemain > 0) {
            setBookingStep(2);
            return ;
        }
        setContext({...context, phoneNumber: phone, userName, bookingPopupStep: 2})
        setTimeRemain(60)
        setResetInterval(true)

        handleReCaptchaVerify().then(token => {
            sendVerification(phone, token)
        })
    }
    const resend = () => {
        handleReCaptchaVerify().then(token => {
            sendVerification(context.phoneNumber, token)
        })
    }

    const verify = (code) => {

        verifyPhoneNumber(context.phoneNumber, context.userName, code).then((res) => {
            if (res.success) {
                setBookingStep(3, {verifiedCode: true, oneTimeCode: res.data.oneTimeHash})
            }
            else {
                setContext({...context, verifiedCode: false})
            }
        });

    }

    const confirmBooking = () => {
        createBooking(salonId, context.phoneNumber, context.oneTimeCode)
            .then(async () => {
                setBookingStep(4)
                await timeout(4000)
                setBookingStep(5)
            })
    }

    useEffect(() => {
        setContext({...context, isLoaded: false})
        const getSalon = async () => {
            const data = await getSalonInfo(salonId)
            setContext({...context, salonInfo: data, isLoaded: true})
        }
        getSalon().catch((e) => console.log(e))
    }, [])

    return (
        <BookingContext.Provider value={{
            ...context,
            setBookingStep,
            enterUser,
            verify,
            confirmBooking,
            resend,
            setTimeRemain,
            setResetInterval,
            timeRemain,
            resetInterval
        }}>
            {props.children}
        </BookingContext.Provider>
    )
}